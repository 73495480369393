import React, { useCallback, useContext, useEffect, useState } from 'react'
import logger from '../logger'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Center, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import ExpenseDistributionChart from '../components/ExpenseDistributionChart'
import { useNavigate } from 'react-router-dom'
import { AppStateContext } from '../hooks/AppStateProvider'
import ProfitChart from '../components/ProfitChart'
import CatSpinner from '../components/CatSpinner'
import MonthlyCategoriesChart from '../components/MonthlyCategoriesChart'
import CashFlowChart from '../components/CashFlowChart'

export default function Analytics() {
  const [appState] = useContext(AppStateContext)
  const [data, setData] = useState(null)
  const navigate = useNavigate()

  const textColor = useColorModeValue('black', '#DCDCE2')

  const fetchAnalytics = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/transactions/analytics`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (res) => {
        if (!res) return
        const resData = await res.json().catch((err) => logger(err))
        if (res.status === 401) navigate('/login')
        if (res.status === 200) {
          setData(resData.data)
        }
      })
      .catch((err) => {
        logger('error fetching accounts', err)
      })
  }, [navigate])

  useEffect(() => {
    document.title = 'Analytics'
    fetchAnalytics()
  }, [fetchAnalytics, appState])

  function renderCharts() {
    if (!data) {
      return (
        <Center
          w={'100%'}
          minH={['50px', '50px', '145px', '145px']}
          borderRadius="7"
          mt={'180px'}
        >
          <CatSpinner size={'200px'} animationType={'spin'} speed={'.5s'} />
        </Center>
      )
    }

    return data.map((year, i) => {
      return (
        <>
          <Row
            style={{ height: '350px', marginBottom: '50px' }}
            key={i}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '45px',
              }}
              color={textColor}
              as="b"
            >
              {year.year}
            </Text>
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Overview
            </Text>
            <Col xs={12} sm={12} md={12}>
              <ProfitChart yearData={year ?? null} />
            </Col>
          </Row>
          <Row
            style={{ height: '350px', marginBottom: '50px' }}
            key={i}
          >
            <Col xs={12} sm={12} md={12}>
              <ExpenseDistributionChart
                transactionsData={year.transactions ?? null}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '500px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Cash Flow
            </Text>
            <Col xs={12} sm={12} md={12}>
              <CashFlowChart
                transactionsData={year.transactions ?? null}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Bills
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'bills'}
                lineColor={'#004CA3'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Car
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'car'}
                lineColor={'#95c8f3'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px' }}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Entertainment
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'entertainment'}
                lineColor={'#8A51A5'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Gas
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'gas'}
                lineColor={'#FFA47E'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px' }}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Groceries
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'groceries'}
                lineColor={'#CB5E99'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Healthcare
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'healthcare'}
                lineColor={'#97312d'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Investments
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'investments'}
                lineColor={'#81e3e1'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Pets
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'pets'}
                lineColor={'#5a7e64'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px' }}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Restaurant
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'restaurant'}
                lineColor={'#F47B89'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Savings
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'savings'}
                lineColor={'#ab7b8d'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px'}}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Subscriptions
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'subscriptions'}
                lineColor={'#db7f50'}
              />
            </Col>
          </Row>
          <Row
            style={{ height: '350px', marginBottom: '50px' }}
            key={year.year}
          >
            <Text
              pl={'15px'}
              fontSize={{
                base: '15px',
                sm: '15px',
                md: '20px',
                lg: '30px',
              }}
              as="i"
              color={textColor}
            >
              Vacation
            </Text>
            <Col xs={12} sm={12} md={12}>
              <MonthlyCategoriesChart
                transactionsData={year.transactions ?? null}
                filterCategory={'vacation'}
                lineColor={'#FFD286'}
              />
            </Col>
          </Row>
        </>
      )
    })
  }

  return (
    <Flex>
      <Container style={{ minHeight: '100vh' }}>
        {renderCharts()}
      </Container>
    </Flex>
  )
}
