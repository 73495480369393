import { useColorModeValue } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, ResponsiveContainer } from 'recharts'
import logger from '../logger'
import { currency } from '../util'

export default function ExpenseDistributionChart({ transactionsData }) {
  const [data, setData] = useState(null)
  const bgColor = useColorModeValue('white', '#252526')

  const calculateExpenseDistribution = useCallback(async () => {
    if (!transactionsData) return
    const expenseMap = {
      bills: {
        name: 'Bills',
        total: 0,
        color: '#004CA3',
      },
      car: {
        name: 'Car',
        total: 0,
        color: '#95c8f3',
      },
      entertainment: {
        name: 'Entertainment',
        total: 0,
        color: '#8A51A5',
      },
      gas: {
        name: 'Gas',
        total: 0,
        color: '#FFA47E',
      },
      groceries: {
        name: 'Groceries',
        total: 0,
        color: '#CB5E99',
      },
      healthcare: {
        name: 'Healthcare',
        total: 0,
        color: '#97312d',
      },
      investments: {
        name: 'Investments',
        total: 0,
        color: '#81e3e1',
      },
      pets: {
        name: 'Pets',
        total: 0,
        color: '#5a7e64',
      },
      restaurant: {
        name: 'Restaurant',
        total: 0,
        color: '#F47B89',
      },
      savings: {
        name: 'Savings',
        total: 0,
        color: '#ab7b8d',
      },
      subscriptions: {
        name: 'Subscriptions',
        total: 0,
        color: '#db7f50',
      },
      vacation: {
        name: 'Vacation',
        total: 0,
        color: '#FFD286',
      },
      uncategorized: {
        name: 'Uncategorized',
        total: 0,
        color: '#FFFFA6',
      },
    }

    transactionsData.forEach((transaction) => {
      switch (transaction.category) {
        case 'bills':
          expenseMap['bills'].total += transaction.amount
          break
        case 'car':
          expenseMap['car'].total += transaction.amount
          break
        case 'entertainment':
          expenseMap['entertainment'].total += transaction.amount
          break
        case 'gas':
          expenseMap['gas'].total += transaction.amount
          break
        case 'groceries':
          expenseMap['groceries'].total += transaction.amount
          break
        case 'healthcare':
          expenseMap['healthcare'].total += transaction.amount
          break
        case 'income':
          break
        case 'investments':
          expenseMap['investments'].total += transaction.amount
          break
        case 'ignore':
          break
        case 'pets':
          expenseMap['pets'].total += transaction.amount
          break
        case 'restaurant':
          expenseMap['restaurant'].total += transaction.amount
          break
        case 'savings':
          expenseMap['savings'].total += transaction.amount
          break
        case 'subscriptions':
          expenseMap['subscriptions'].total += transaction.amount
          break
        case 'vacation':
          expenseMap['vacation'].total += transaction.amount
          break
        case 'uncategorized':
          expenseMap['uncategorized'].total += transaction.amount
          break
        default:
          logger('unknown expense category: ', transaction.category)
      }
    })

    let graphData = []
    Object.keys(expenseMap).forEach((key) => {
      expenseMap[key].total = -1 * expenseMap[key].total
    })
    Object.keys(expenseMap)
      .filter((key) => expenseMap[key].total !== 0)
      .map((key) => {
        return graphData.push(expenseMap[key])
      })

    setData(graphData)
  }, [transactionsData])

  useEffect(() => {
    calculateExpenseDistribution()
  }, [calculateExpenseDistribution])

  if (!data || !transactionsData) return null

  const CustomizedLabel = (props) => {
    const textColor = useColorModeValue('black', '#DCDCE2')
    const { x, y, fill, value } = props
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="16"
        fontWeight={'bold'}
        textAnchor="start"
        fill={textColor}
      >
        {currency.format(value)}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        style={{ backgroundColor: bgColor, borderRadius: '10px' }}
        margin={{ top: 25, left: 15, right: 15, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <Bar dataKey="total" label={<CustomizedLabel />}>
          {data.map((entry, index) => (
            <Cell key={index} fill={data[index].color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
