import { useColorModeValue } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState, PureComponent } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import logger from '../logger'
import { currency } from '../util'

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={14} textAnchor="middle">
        {currency.format(value)}
      </text>
    )
  }
}

export default function MonthlyCategoriesChart({
  transactionsData,
  filterCategory,
  lineColor,
}) {
  const [data, setData] = useState(null)
  const textColor = useColorModeValue('black', 'white')

  const calculateMonthData = useCallback(async () => {
    if (!transactionsData) return
    const expenseMap = {
      january: {
        name: 'JAN',
        total: 0,
      },
      february: {
        name: 'FEB',
        total: 0,
      },
      march: {
        name: 'MAR',
        total: 0,
      },
      april: {
        name: 'APR',
        total: 0,
      },
      may: {
        name: 'MAY',
        total: 0,
      },
      june: {
        name: 'JUN',
        total: 0,
      },
      july: {
        name: 'JUL',
        total: 0,
      },
      august: {
        name: 'AUG',
        total: 0,
      },
      september: {
        name: 'SEP',
        total: 0,
      },
      october: {
        name: 'OCT',
        total: 0,
      },
      november: {
        name: 'NOV',
        total: 0,
      },
      december: {
        name: 'DEC',
        total: 0,
      },
    }

    transactionsData.forEach((transaction) => {
      if (transaction.category === filterCategory) {
        const month = `${transaction.date}`.slice(5, 7)
        switch (month) {
          case '01':
            expenseMap['january'].total += transaction.amount
            break
          case '02':
            expenseMap['february'].total += transaction.amount
            break
          case '03':
            expenseMap['march'].total += transaction.amount
            break
          case '04':
            expenseMap['april'].total += transaction.amount
            break
          case '05':
            expenseMap['may'].total += transaction.amount
            break
          case '06':
            expenseMap['june'].total += transaction.amount
            break
          case '07':
            expenseMap['july'].total += transaction.amount
            break
          case '08':
            expenseMap['august'].total += transaction.amount
            break
          case '09':
            expenseMap['september'].total += transaction.amount
            break
          case '10':
            expenseMap['october'].total += transaction.amount
            break
          case '11':
            expenseMap['november'].total += transaction.amount
            break
          case '12':
            expenseMap['december'].total += transaction.amount
            break
          default:
            logger('unknown month: ', transaction.category)
        }
      }
    })

    let graphData = []
    Object.keys(expenseMap).forEach((key) => {
      expenseMap[key].total = -1 * expenseMap[key].total
    })
    Object.keys(expenseMap)
      .map((key) => {
        return graphData.push(expenseMap[key])
      })

    setData(graphData)
  }, [transactionsData, filterCategory])

  useEffect(() => {
    calculateMonthData()
  }, [calculateMonthData])

  if (!data || !transactionsData) return null

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${currency.format(payload[0].value)}`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="total"
          stroke={lineColor}
          label={<CustomizedLabel stroke={textColor} />}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
