import React from 'react'
import cat from '../images/cat.png'

export default function CatSpinner({ size, animationType, speed }) {
  return (
    <img
      style={{
        width: size,
        height: size,
        borderRadius: '100px',
        animation: `${animationType} ${speed} linear infinite`,
      }}
      src={cat}
      alt="img"
    />
  )
}
