import { useColorModeValue } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState, PureComponent } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import logger from '../logger'
import { currency } from '../util'

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={14} textAnchor="middle">
        {currency.format(value)}
      </text>
    )
  }
}

export default function CashFlowChart({ transactionsData }) {
  const [data, setData] = useState(null)
  const textColor = useColorModeValue('black', 'white')
  const bgColor = useColorModeValue('white', 'black')

  function distributeTransaction(transaction, expenseMap, month) {
    if (transaction.category === 'income') {
      expenseMap[month].income += transaction.amount
    } else if (
      transaction.category !== 'ignore' &&
      transaction.category !== 'investments' &&
      transaction.category !== 'savings'
    ) {
      expenseMap[month].expenses += transaction.amount
    }
  }

  const calculateMonthData = useCallback(async () => {
    if (!transactionsData) return
    const expenseMap = {
      january: {
        name: 'JAN',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      february: {
        name: 'FEB',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      march: {
        name: 'MAR',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      april: {
        name: 'APR',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      may: {
        name: 'MAY',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      june: {
        name: 'JUN',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      july: {
        name: 'JUL',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      august: {
        name: 'AUG',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      september: {
        name: 'SEP',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      october: {
        name: 'OCT',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      november: {
        name: 'NOV',
        income: 0,
        expenses: 0,
        profit: 0,
      },
      december: {
        name: 'DEC',
        income: 0,
        expenses: 0,
        profit: 0,
      },
    }

    transactionsData.forEach((transaction) => {
      const month = `${transaction.date}`.slice(5, 7)
      switch (month) {
        case '01':
          distributeTransaction(transaction, expenseMap, 'january')
          break
        case '02':
          distributeTransaction(transaction, expenseMap, 'february')
          break
        case '03':
          distributeTransaction(transaction, expenseMap, 'march')
          break
        case '04':
          distributeTransaction(transaction, expenseMap, 'april')
          break
        case '05':
          distributeTransaction(transaction, expenseMap, 'may')
          break
        case '06':
          distributeTransaction(transaction, expenseMap, 'june')
          break
        case '07':
          distributeTransaction(transaction, expenseMap, 'july')
          break
        case '08':
          distributeTransaction(transaction, expenseMap, 'august')
          break
        case '09':
          distributeTransaction(transaction, expenseMap, 'september')
          break
        case '10':
          distributeTransaction(transaction, expenseMap, 'october')
          break
        case '11':
          distributeTransaction(transaction, expenseMap, 'november')
          break
        case '12':
          distributeTransaction(transaction, expenseMap, 'december')
          break
        default:
          logger('unknown month: ', transaction.month)
      }
    })

    let graphData = []
    Object.keys(expenseMap).forEach((key) => {
      // expenseMap[key].total = -1 * expenseMap[key].total
      expenseMap[key].profit = expenseMap[key].income + expenseMap[key].expenses
    })

    Object.keys(expenseMap)
      .filter((key) => expenseMap[key].total !== 0)
      .map((key) => {
        return graphData.push(expenseMap[key])
      })

    setData(graphData)
  }, [transactionsData])

  useEffect(() => {
    calculateMonthData()
  }, [calculateMonthData])

  if (!data || !transactionsData) return null

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: bgColor,
            borderRadius: '5px',
            padding: '5px',
          }}
        >
          <p className="label">{`Income: ${currency.format(
            payload[0].value
          )}`}</p>
          <p className="label">{`Expenses: ${currency.format(
            payload[1].value
          )}`}</p>
          <p className="label">{`Profit: ${currency.format(
            payload[2].value
          )}`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="income"
          stroke="green"
          label={<CustomizedLabel stroke={textColor} />}
        />
        <Line
          type="monotone"
          dataKey="expenses"
          stroke="red"
          label={<CustomizedLabel stroke={textColor} />}
        />
        <Line
          type="monotone"
          dataKey="profit"
          stroke="blue"
          label={<CustomizedLabel stroke={textColor} />}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
