import React, { useContext } from 'react'
import {
  Box,
  Center,
  Text,
  Spacer,
  Select,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { AppStateContext } from '../hooks/AppStateProvider'
import { monthMap } from '../util'
import CatSpinner from './CatSpinner'

export default function TimeRangeSelect({ availableYears }) {
  const [appState, setAppState] = useContext(AppStateContext)
  const bgColor = useColorModeValue('white', '#252526')
  const textColor = useColorModeValue('black', '#DCDCE2')

  function renderMonthSelection() {
    let selection = []
    selection.push('ALL')
    for (let i = 1; i <= 12; i++) {
      selection.push(monthMap[i])
    }

    return (
      <Select
        defaultValue={appState.selectedMonth}
        mb={3}
        onChange={(event) => {
          setAppState({
            selectedMonth: event.target.value,
            selectedYear: appState.selectedYear,
            filteredCategory: appState.filteredCategory,
          })
        }}
      >
        {selection.map((val) => {
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </Select>
    )
  }

  function renderYearSelection() {
    let years = availableYears.sort()
    let selection = []
    years.forEach((year) => {
      selection.push(year)
    })

    return (
      <Select
        defaultValue={appState.selectedYear}
        mb={3}
        onChange={(event) => {
          setAppState({
            selectedMonth: appState.selectedMonth,
            selectedYear: event.target.value,
            filteredCategory: appState.filteredCategory,
          })
        }}
      >
        {selection.map((val) => {
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </Select>
    )
  }

  if (!availableYears) {
    return (
      <Center
        w={'100%'}
        minH={['50px', '50px', '145px', '145px']}
        bg={bgColor}
        borderRadius="7"
      >
        <CatSpinner size={'75px'} animationType={'spin'} speed={'.5s'} />
      </Center>
    )
  }

  return (
    <Box
      bg={bgColor}
      w={'100%'}
      minH={['50px', '50px', '145px', '145px']}
      mb={5}
      borderRadius="7"
    >
      <VStack alignItems={'start'} p={5}>
        <Text
          pl={'2px'}
          fontSize={{
            base: '8px',
            sm: '10px',
            md: '18px',
            lg: '18px',
          }}
          color={textColor}
        >
          Time Range
        </Text>
        <Spacer />
        {renderYearSelection()}
        {renderMonthSelection()}
      </VStack>
    </Box>
  )
}
