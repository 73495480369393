import { useColorModeValue } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, ResponsiveContainer } from 'recharts'
import { currency } from '../util'

export default function ProfitChart({ yearData }) {
  const [data, setData] = useState(null)
  const bgColor = useColorModeValue('white', '#252526')

  const setGraphData = useCallback(async () => {
    if (!yearData) return
    let graphData = [
      {
        name: 'Income',
        total: yearData.income,
        color: '#77df79',
      },
      {
        name: 'Expenses',
        total: yearData.expenses * -1,
        color: '#ff686b',
      },
      {
        name: 'Profit',
        total: yearData.profit,
        color: '#a8e4ef',
      },
    ]

    setData(graphData)
  }, [yearData])

  useEffect(() => {
    setGraphData()
  }, [setGraphData])

  if (!data || !yearData) return null

  const CustomizedLabel = (props) => {
    const textColor = useColorModeValue('black', '#DCDCE2')
    const { x, y, fill, value } = props
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="16"
        fontWeight={'bold'}
        textAnchor="start"
        fill={textColor}
      >
        {currency.format(value)}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        style={{ backgroundColor: bgColor, borderRadius: '10px' }}
        margin={{ top: 25, left: 15, right: 15, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <Bar dataKey="total" label={<CustomizedLabel />}>
          {data.map((entry, index) => (
            <Cell key={index} fill={data[index].color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
