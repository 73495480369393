import React, { useEffect, useState } from 'react'

import {
  Text,
  Spacer,
  VStack,
  Center,
  HStack,
  Spinner,
  Container,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react'
import RepairAccountBtn from '../components/RepairAccountBtn'
import { IoIosWarning } from 'react-icons/io'
import logger from '../logger'

export default function DisconnectedAccounts({ onSuccess, forceRefresh }) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const stackBgColor = useColorModeValue('white', 'gray.900')
  const bgColor = useColorModeValue('white', '#252526')
  const tooltipBg = useColorModeValue('white', 'gray.900')
  const tooltipColor = useColorModeValue('black', 'white')

  useEffect(() => {
    if (loading) {
      fetch(`${process.env.REACT_APP_API_URL}/enrollments`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          if (!res) return
          const resData = await res.json().catch((err) => logger(err))
          if (res.status === 200 && resData.enrollments) {
            setData(resData.enrollments)
          }
          setLoading(false)
        })
        .catch((err) => {
          logger('error getting enrollments', err)
        })
    }
  }, [loading])

  function renderAccounts() {
    if (data.length === 0 && !loading) {
      return null
    }

    return data.map((enrollment) => {
      if (enrollment.disconnected) {
        return (
          <HStack
            key={enrollment.id}
            width={'100%'}
            borderWidth="1px"
            borderRadius="lg"
            bg={stackBgColor}
            boxShadow={'2xl'}
            p={3}
            mb={5}
          >
            <Text fontSize="xl" as="b" color={'red'}>
              {String(enrollment.institution).toUpperCase()} has lost
              connection. Click REPAIR to resolve.
            </Text>
            <Spacer />

            <Tooltip
              label={`This account has lost connection to your financial instituion. Click 'Repair' to resolve this issue.`}
              fontSize="md"
              bg={tooltipBg}
              color={tooltipColor}
              borderWidth="1px"
              boxShadow={'2xl'}
              borderRadius="lg"
              p={5}
            >
              <span>
                <IoIosWarning size={'40px'} color={'red'} />
              </span>
            </Tooltip>

            <RepairAccountBtn
              enrollment={enrollment}
              onSuccess={onSuccess}
              forceRefresh={forceRefresh}
            />
          </HStack>
        )
      }
    })
  }

  return renderAccounts()
}
