import React, { createContext, useState } from 'react'
import { DateTime } from 'luxon'
import { monthMap } from '../util'

export const AppStateContext = createContext()

const AppStateProvider = (props) => {
  const [appState, setAppState] = useState({
    selectedMonth: monthMap[DateTime.now().month],
    selectedYear: DateTime.now().year,
    filteredCategory: 'all',
  })

  return (
    <AppStateContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
